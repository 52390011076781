import services from "../axios/dbManag";

export const rinnovaSelezionati = (anno, id_Societa, json_In) => {
  return services.dbManag
    .post("/tesseramento/societa/rinnovi/rinnova", {
      anno,
      id_Societa,
      json_In,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
