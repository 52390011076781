<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-md-8 text-start d-flex">
        <button
          v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
          style="margin-left: 19px"
          type="button"
          class="badge bg-secondary rounded blueFit fs-5"
          @click="rinnova"
          :disabled="disableButton"
        >
          Rinnova selezionati
        </button>
        <!--   <span type="button" class="badge bg-light-danger rounded text-danger">
          Elimina selezionati
        </span> -->
        <div>
          <div class="text-start mt-2" style="margin-left: 19px">
            Totale disponibile: <b> {{ totaleDisponibile }} €</b>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="text-end mt-2" style="margin-right: 19px">
          Totale tessere: <b>{{ record ? record : 0 }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        rinnovi_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204) &&
        status === 200
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
      <!-- <span class="sr-only">Loading...</span> -->
    </div>
    <div
      v-else-if="
        rinnovi_list.length === 0 &&
        loaded === true &&
        status != 204 &&
        status != 200
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="rinnovi_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        :checkedAll="checkAll"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsRinnovo"
        @current-change="setCurrentPageRinnovo"
        @checkboxClicked="
          checkAll = !checkAll;
          rinnovi_list.forEach((element) => {
            element.selected = checkAll;
          });
        "
      >
        <template v-slot:cell-checkbox="{ row: data }"
          ><input
            type="checkbox"
            id="scales"
            name="tesseramentiSelected"
            @click="checkAll = false"
            v-model="data.selected"
            v-if="puo_utilizzare_pulsanti_sezioni && !readOnly"
        /></template>
        <template v-slot:cell-nominativo="{ row: data }"
          ><div class="tab-long">
            <router-link
              :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
            >
              {{ data.nominativo }}</router-link
            >
          </div>
        </template>
        <template v-slot:cell-cod_tessera="{ row: data }"
          ><div class="">
            {{ data.cod_tessera }}
          </div>
        </template>
        <template v-slot:cell-data_certificato="{ row: data }"
          ><div class="tab-long">
            <!-- <div class="tab-long" v-show="data.id_tipo_tessera == 1"> -->
            <Datepicker
              v-show="data.id_tipo_tessera != 6"
              v-model="data.data_certificato"
              @update:modelValue="
                $event ? (data.data_certificato = fromUTCtoISODate($event)) : ''
              "
              placeholder=""
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              :disabled="!puo_utilizzare_pulsanti_sezioni || readOnly"
            />
          </div>
        </template>
        <template v-slot:cell-categoria="{ row: data }"
          ><div v-if="data.id_tipo_tessera != 6" class="tab-long">
            {{ data.categoria }}
          </div>
          <div v-if="data.id_tipo_tessera == 6" class="tab-long">Socio</div>
        </template>
        <template v-slot:cell-privacy_1="{ row: data }">
          <input
            type="checkbox"
            name="privacy_1"
            :checked="data.privacy_1"
            v-model="data.privacy_1"
            :disabled="!puo_utilizzare_pulsanti_sezioni || data.privacy_fornita"
          />
        </template>
        <template v-slot:cell-privacy_2="{ row: data }"
          ><div class="">
            <input
              type="checkbox"
              name="privacy_2"
              :checked="data.privacy_2"
              v-model="data.privacy_2"
              :disabled="
                !puo_utilizzare_pulsanti_sezioni || data.privacy_fornita
              "
            />
          </div>
        </template>
        <template v-slot:cell-privacy_3="{ row: data }"
          ><div class="">
            <input
              type="checkbox"
              name="privacy_3"
              :checked="data.privacy_3"
              v-model="data.privacy_3"
              :disabled="
                !puo_utilizzare_pulsanti_sezioni || data.privacy_fornita
              "
            />
          </div>
        </template>
        <template v-slot:cell-privacy_4="{ row: data }">
          <input
            type="checkbox"
            name="privacy_4"
            :checked="data.privacy_4"
            v-model="data.privacy_4"
            :disabled="!puo_utilizzare_pulsanti_sezioni || data.privacy_fornita"
          />
        </template>
        <template v-slot:cell-privacy_5="{ row: data }">
          <input
            type="checkbox"
            name="privacy_5"
            :checked="data.privacy_5"
            v-model="data.privacy_5"
            :disabled="!puo_utilizzare_pulsanti_sezioni || data.privacy_fornita"
          />
        </template>
        <template v-slot:cell-loyalty_program="{ row: data }"
          ><div class="">
            <input
              type="checkbox"
              name="loyalty_program"
              :checked="data.loyalty_program"
              v-model="data.loyalty_program"
              :disabled="!puo_utilizzare_pulsanti_sezioni"
            />
          </div>
        </template>
      </Datatable>
    </div>
    <br />
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { format } from "@/composables/formatDate";
import { fromUTCtoISODate } from "@/composables/formatDate";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { useStore } from "vuex";
import { ref, computed } from "vue";

import { rinnovaSelezionati } from "@/requests/rinnoviUnaSocRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "table-rinnovi-societa",
  emits: ["getRinnovi", "resetFilters"],
  components: { Datepicker, Datatable, Loading },
  props: {
    societa: {
      type: Object,
      required: true,
    },
    totaleDisponibile: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const rinnovi_list = computed(() =>
      store.getters.getStateFromName("resultsrinnovi_list")
    );

    const tesseratiSelected = ref([]);

    const stagione = computed(() => store.getters.stagione_rinnRinnovo);

    const disableButton = ref(false);
    const isLoading = ref(false);

    const rinnova = () => {
      tesseratiSelected.value = [];
      disableButton.value = true;
      isLoading.value = true;
      rinnovi_list.value.forEach((element) => {
        if (element.selected == true) {
          tesseratiSelected.value.push(element);
        }
      });
      const privacyNotAccepted = ref(
        tesseratiSelected.value.some((element) => element.privacy_1 == false)
      );
      const certificatoScaduto = ref(
        tesseratiSelected.value.some(
          (element) =>
            element.id_tipo_tessera != 6 &&
            (element.data_certificato == null ||
              new Date(element.data_certificato) <= new Date())
        )
      );
      const obbligo_certificato = ref(
        tesseratiSelected.value.some(
          (element) => element.obbligo_certificato == true
        )
      );

      if (tesseratiSelected.value.length > 0) {
        if (
          privacyNotAccepted.value == true &&
          certificatoScaduto.value == false &&
          obbligo_certificato.value == true
        ) {
          disableButton.value = false;
          isLoading.value = false;
          alertFailed(
            "Attenzione! Per procedere con i rinnovi è necessario che tutti i tesserati abbiano accettato la Privacy 1.A"
          );
          return;
        } else if (
          certificatoScaduto.value == true &&
          obbligo_certificato.value == true &&
          privacyNotAccepted.value == false
        ) {
          disableButton.value = false;
          isLoading.value = false;
          alertFailed(
            "Attenzione! Per procedere con i rinnovi è necessario che tutti i tesserati (Atleta con età maggiore di 5 anni) abbiano un certificato medico valido"
          );
          return;
        } else if (
          certificatoScaduto.value == true &&
          obbligo_certificato.value == true &&
          privacyNotAccepted.value == true
        ) {
          disableButton.value = false;
          isLoading.value = false;
          alertFailed(
            "Attenzione! Per procedere è necessario che tutti i tesserati accettino la Privacy 1.A e che gli atleti (età maggiore di 5 anni) abbiano un certificato medico valido"
          );
          return;
        } else {
          rinnovaSelezionati(
            stagione.value,
            props.societa.id,
            JSON.stringify(tesseratiSelected.value)
          ).then((res) => {
            disableButton.value = false;
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("Rinnovi effettuati correttamente");
              emit("getRinnovi");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      } else {
        disableButton.value = false;
        isLoading.value = false;
        alertFailed(
          "Per procedere è necessario selezionare uno o più tesserati"
        );
      }
    };

    const tableHeader = ref([
      {
        name: "",
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Tesserato",
        key: "nominativo",
        sortable: false,
      },
      {
        name: "Codice Tessera",
        key: "cod_tessera",
        sortable: false,
      },
      {
        name: "Scadenza certificato",
        key: "data_certificato",
        sortable: false,
        align: "center",
      },
      {
        name: "Tipo tessera",
        key: "categoria",
        sortable: false,
      },
      {
        name: "Privacy 1.A",
        key: "privacy_1",
        sortable: false,
      },
      {
        name: "Privacy 1.B",
        key: "privacy_2",
        sortable: false,
      },
      {
        name: "Privacy 1.C",
        key: "privacy_3",
        sortable: false,
      },
      {
        name: "Privacy 1.D",
        key: "privacy_4",
        sortable: false,
      },
      {
        name: "Privacy 1.E",
        key: "privacy_5",
        sortable: false,
      },
      {
        name: "Loyalty Program",
        key: "loyalty_program",
        sortable: false,
      },
    ]);

    // quando utente cambia qualcosa nel Datatable, chiamo una di queste funzioni
    const setFetchRowsRinnovo = (e) => {
      store.commit("setFetchRowsRinnovo", e);
      emit("getRinnovi");
    };
    const setCurrentPageRinnovo = (page) => {
      store.commit("setCurrentPageRinnovo", page);
      emit("getRinnovi");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnRinnovo", columnName);
      store.commit("setSortOrderRinnovo", order);
      emit("getRinnovi");
    };
    return {
      format,
      isEnabled,
      fromUTCtoISODate,
      rinnovi_list: computed(() =>
        store.getters.getStateFromName("resultsrinnovi_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedrinnovi_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordrinnovi_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusrinnovi_list")
      ),
      // elementi utili per configurazione del componente "Datatable"
      currentPage: computed(() => store.getters.currentPageRinnovo),
      rowsToSkip: computed(() => store.getters.rowsToSkipRinnovo),
      fetchRows: computed(() => store.getters.fetchRowsRinnovo),
      sortColumn: computed(() => store.getters.sortColumnRinnovo),
      sortOrder: computed(() => store.getters.sortOrderRinnovo),
      setFetchRowsRinnovo,
      setCurrentPageRinnovo,
      setSortOrderColumn,
      tableHeader,

      rinnova,
      tesseratiSelected,
      disableButton,
      isLoading,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
};
</script>

<style></style>
