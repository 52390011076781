<template>
  <div>
    <FiltriRinnoviSocieta
      @getRinnovi="searchRinnovoList"
      @resetFilters="resetFilters"
      :societa="societa"
    />
    <TableRinnoviSocieta
      @getRinnovi="getRinnovi"
      @resetFilters="resetFilters"
      :societa="societa"
      :totaleDisponibile="totaleDisponibile"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriRinnoviSocieta from "@/components/components-fit/società/rinnovi/FiltriRinnoviSocieta.vue";
import TableRinnoviSocieta from "@/components/components-fit/società/rinnovi/TableRinnoviSocieta.vue";
import { useStore } from "vuex";
import { trimInput } from "@/composables/trimInput";

import { getRiepilogoEconomato } from "@/requests/economato";
import { alertFailed } from "@/composables/swAlert";

export default defineComponent({
  name: "rinnovi-societa",
  components: {
    FiltriRinnoviSocieta,
    TableRinnoviSocieta,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // inclusioni store e global api
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    // stagione da store
    // const stagione = computed(() => store.getters.stagioneSelected);
    // filtri da store (store>modules>FiltriTesseratiSocieta.js)
    const cognome = computed(() => store.getters.cognomeRinnovo);
    const codiceTessera = computed(() => store.getters.codiceTesseraRinnovo);
    const data_emissione_da = computed(
      () => store.getters.data_emissione_daRinnovo
    );
    const data_emissione_a = computed(
      () => store.getters.data_emissione_aRinnovo
    );
    const id_tipo_tessera = computed(
      () => store.getters.id_tipo_tesseraRinnovo
    );
    const id_categoria = computed(() => store.getters.id_categoriaRinnovo);
    //const id_qualifica = computed(() => store.getters.id_qualificaRinnovo);
    const rowsToSkip = computed(() => store.getters.rowsToSkipRinnovo);
    const fetchRows = computed(() => store.getters.fetchRowsRinnovo);
    const sortColumn = computed(() => store.getters.sortColumnRinnovo);
    const sortOrder = computed(() => store.getters.sortOrderRinnovo);
    const stagione_prec = computed(() => store.getters.stagione_precRinnovo);
    const stagione_rinn = computed(() => store.getters.stagione_rinnRinnovo);
    const id_nazione = computed(() => store.getters.id_nazioneRinnovo);
    const stato_Nascita_Italia = computed(
      () => store.getters.id_nazioni_genericheRinnovo
    );
    const cittadinanza = computed(() => store.getters.cittadinanzaRinnovo);
    const estiva = computed(() => store.getters.estivaRinnovo);
    const disabile = computed(() => store.getters.disabileRinnovo);
    const id_Disciplina = computed(() => store.getters.id_disciplinaRinnovo);
    const consiglio = computed(() => store.getters.consiglioRinnovo);

    const getRinnovi = () => {
      getTotale();
      // attivato tramite evento @getRinnovi
      // prendo lista da store dinamico. Passo keys (i parametri in input)
      // apiLink(link all'api), itemName(nome dell'array)
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione_Prec: stagione_prec.value,
          stagione_Rinn: stagione_rinn.value,
          cognome: trimInput(cognome.value),
          id_Nazione: id_nazione.value,
          stato_Nascita_Italia: stato_Nascita_Italia.value,
          disabile: disabile.value,
          cittadinanza: cittadinanza.value,
          id_Tipo_Tessera: id_tipo_tessera.value,
          id_Tipologia: id_categoria.value,
          //id_qualifica: id_qualifica.value,
          id_Disciplina: id_Disciplina.value,
          cod_tessera: trimInput(codiceTessera.value),
          data_Emissione_Da: data_emissione_da.value,
          data_Emissione_A: data_emissione_a.value,
          estiva: estiva.value,
          consiglio: consiglio.value,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.TESSERATI_RINNOVI_LIST,
        itemName: "rinnovi_list",
      });
    };
    // reset filtri. viene attivato tramite evento @resetFilters
    const resetFilters = async () => {
      store.commit("resetFiltersRinnovo");
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      store.commit("spliceArrayFromName", "stati_nascita");
      await getTotale();
      await getRinnovi();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedrinnovi_list")
    );
    const searchRinnovoList = async () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipRinnovo");
      await getTotale();
      await getRinnovi();
    };

    const totaleDisponibile = ref(null);
    const getTotale = () => {
      totaleDisponibile.value = null;
      getRiepilogoEconomato(props.societa.id, stagione_rinn.value).then(
        (res) => {
          if (res.status && res.status != 200)
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );

          totaleDisponibile.value = res.results[0].disponibile;
        }
      );
    };
    watch(stagione_rinn, () => {
      getTotale();
    });

    onMounted(async () => {
      await getTotale();
      await getRinnovi();
      setCurrentPageBreadcrumbs("Rinnovi Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    return {
      getRinnovi,
      resetFilters,
      searchRinnovoList,
      totaleDisponibile,
    };
  },
});
</script>
