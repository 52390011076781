<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getRinnovi')">
        <div class="row">
          <div class="col-sm-3 mb-4">
            <label>Stagione precedente:</label>
            <SelectInput
              :options="stagioni"
              name="stagione_prec"
              placeholder="Stagione precedente"
              :value="stagione_prec"
              @changeSelect="setStagionePrec($event)"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <label>Stagione rinnovo:</label>
            <SelectInput
              :options="stagioni"
              name="stagione_rinn"
              placeholder="Stagione rinnovo"
              :value="stagione_rinn"
              @changeSelect="setStagioneRinn($event)"
            />
          </div>
          <div
            class="col-sm-6 mb-4 align-self-end"
            v-if="
              stagione_prec == new Date().getFullYear() &&
              stagione_prec == stagione_rinn &&
              new Date(`${new Date().getFullYear()}/08/16`) > new Date() &&
              new Date(`${new Date().getFullYear()}/06/15`) <= new Date()
            "
          >
            <span
              class="badge bg-light-danger rounded text-danger text-start fs-6 w-100"
              style="white-space: normal !important; line-height: 17px"
            >
              Impostando entrambe le stagioni sulla stagione attuale è possibile
              rinnovare tessere estive, se presenti, di ulteriori 30 giorni
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-4">
            <input
              type="text"
              class="form-control"
              placeholder="Cognome"
              aria-label=""
              :value="cognome"
              @input="setCognomeRinnovo"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <SelectInput
              :options="nazioneExLookup"
              name="nazioneExLookup"
              placeholder="Tesserati nati in"
              :value="id_nazioni_generiche"
              @changeSelect="setNazioniGeneriche($event)"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <select
              class="form-select"
              aria-label=""
              v-if="selectedNazione == true"
            >
              <option selected>Italia</option>
            </select>
            <SelectInput
              v-else
              :options="statiNascita"
              name="nazioni_nascita"
              placeholder="Nazione"
              :value="id_nazione"
              @changeSelect="setNazioneNascita($event)"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <SelectInput
              :options="cittadinanzaLookup"
              name="cittadinanzaLookup"
              placeholder="Cittadinanza"
              :value="id_cittadinanza"
              @changeSelect="setCittadinanza($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-4">
            <input
              type="text"
              class="form-control"
              placeholder="Codice Tessera"
              aria-label=""
              :value="codiceTessera"
              @input="setCodiceTesseraRinnovo"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <SelectInput
              :options="tipoTessera"
              name="tipo_tessera"
              placeholder="Tipo"
              :value="id_tipo_tessera"
              @changeSelect="setTipoTessera($event)"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <SelectInput
              :options="categoria"
              name="categoria"
              placeholder="Categoria"
              :value="id_categoria"
              @changeSelect="setCategoriaTessera($event)"
              :disabled="id_tipo_tessera != 1"
            />
          </div>
          <div class="col-sm-3 mb-4">
            <SelectInput
              :options="discipline"
              name="Settore"
              placeholder="Settore"
              :value="id_disciplina"
              @changeSelect="setDisciplina($event)"
              :disabled="id_tipo_tessera != 1"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 filter">
            <Datepicker
              v-model="data_emissione_da"
              yearPicker
              placeholder="Data Emissione da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              @update:model-value="setdata_emissione_daRinnovo"
              autoApply
            />
          </div>
          <div class="col-sm-3 filter">
            <Datepicker
              v-model="data_emissione_a"
              yearPicker
              placeholder="Data Emissione a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              @update:model-value="setdata_emissione_aRinnovo"
              autoApply
            />
          </div>
          <div class="col-sm-3 filter">
            <SelectInput
              :options="trueFalseLookup"
              name="trueFalseLookup"
              placeholder="Disabile"
              :value="isDisabile"
              @changeSelect="setDisabile($event)"
            />
          </div>
          <div class="col-sm-3 filter align-self-center">
            <input
              class="form-check-input ps-5"
              type="checkbox"
              :value="consiglio"
              :checked="consiglio == true"
              @click="
                setconsiglio = !setconsiglio;
                setConsiglio(setconsiglio);
              "
            />
            <label class="fw-bold ps-2 text-gray-800">
              Filtra membri consiglio</label
            >
            <!--  <SelectInput
              :options="trueFalseLookup"
              name="trueFalseLookup"
              placeholder="Estiva"
              :value="isEstiva"
              @changeSelect="setEstiva($event)"
            /> -->
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('getRinnovi')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, getCurrentInstance, ref, watch } from "vue";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtriRinnoviSocieta",
  emits: ["getRinnovi", "resetFilters"],
  components: {
    SelectInput,
    Datepicker,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    // prendo i filtri da store
    const stagioni = computed(() =>
      store.getters.getStateFromName("affiliazioni_stagioni")
    );
    const tipoTessera = computed(() =>
      store.getters.getStateFromName("tesseramento_tipo_tessere")
    );
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const keys = ref("as|ttt|d");
    // verifico se i filtri sono vuoti, se si li prendo da api, passando le keys qui sopra e il link all'api
    if (!stagioni.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagione = computed(() => store.getters.stagioneSelected);
    // quando cambio stagione prendo i tesserati, passando l'evento al componente "TesseratiUnaSocieta"
    watch(stagione, () => {
      emit("getTesserati");
    });

    // quando cambio i filtri, cambio i corrispettivi elementi nello store
    const setdata_emissione_aRinnovo = (date) => {
      store.commit("setdata_emissione_aRinnovo", date);
    };
    const setdata_emissione_daRinnovo = (date) => {
      store.commit("setdata_emissione_daRinnovo", date);
    };
    const setCodiceTesseraRinnovo = (e) => {
      const string = e.target.value;
      store.commit("setCodiceTesseraRinnovo", string);
    };
    const setCognomeRinnovo = (e) => {
      const string = e.target.value;
      store.commit("setCognomeRinnovo", string);
    };
    const setStagionePrec = (anno) => {
      store.commit("setStagionePrec", anno);
    };
    const setStagioneRinn = (anno) => {
      store.commit("setStagioneRinn", anno);
    };
    const setTipoTessera = (idTipo) => {
      store.commit("setTipoTesseraRinnovo", idTipo);
      store.commit("setCategoriaTesseraRinnovo", null);
      // pulisce le categorie
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      // le nuove categorie
      if (idTipo != null) {
        store.dispatch("setStoreData", {
          keys: { id_tipo_tessera: idTipo },
          apiLink: globalApi.TESSERAMENTO_TIPOLOGIE,
        });
      }
    };
    const setCategoriaTessera = (idCat) => {
      store.commit("setCategoriaTesseraRinnovo", parseInt(idCat));
    };
    const setSettore = (idSet) => {
      store.commit("setSettoreRinnovo", parseInt(idSet));
    };
    const setDisabile = (bool) => {
      store.commit("setDisabileRinnovo", bool);
    };
    const setEstiva = (bool) => {
      store.commit("setEstivaRinnovo", bool);
    };
    const consiglio = computed(() => store.getters.consiglioRinnovo);
    const setconsiglio = ref(false);
    const setConsiglio = (event) => {
      store.commit("setConsiglioRinnovo", event);
    };
    const setCittadinanza = (id) => {
      store.commit("setCittadinanzaRinnovo", parseInt(id));
    };

    const setQualifica = (id) => {
      store.commit("setQualificaRinnovo", parseInt(id));
    };
    const setDisciplina = (id) => {
      store.commit("setDisciplinaRinnovo", parseInt(id));
    };

    const selectedNazione = ref("");
    const setNazioniGeneriche = (bool) => {
      store.commit("setNazioniGenericheRinnovo", bool);
      store.commit("setNazioneNascitaRinnovo", null);
      store.commit("spliceArrayFromName", "stati_nascita");
      selectedNazione.value = bool;
      if (bool == false) {
        store.dispatch("setStoreData", {
          keys: { keys: "sn" },
          apiLink: globalApi.COMBOLIST_GET,
        });
      }
    };
    const setNazioneNascita = (id) => {
      store.commit("setNazioneNascitaRinnovo", parseInt(id));
    };

    return {
      setDisciplina,
      setNazioneNascita,
      setNazioniGeneriche,
      setCittadinanza,
      setEstiva,
      setDisabile,
      setSettore,
      setTipoTessera,
      setCategoriaTessera,
      selectedNazione,
      setQualifica,
      setdata_emissione_daRinnovo,
      setdata_emissione_aRinnovo,
      setCognomeRinnovo,
      setStagionePrec,
      setStagioneRinn,
      setCodiceTesseraRinnovo,
      stagioni,
      tipoTessera,
      discipline,
      stagione,
      statiNascita: computed(() =>
        store.getters.getStateFromName("stati_nascita")
      ),
      certificato_medico: [
        {
          id: 1,
          label: "In Scadenza",
        },
        {
          id: 2,
          label: "Scaduto",
        },
      ],
      categoria: computed(() =>
        store.getters.getStateFromName("tesseramento_tipologie")
      ),
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      nazioneExLookup: [
        { id: true, label: "Italia" },
        { id: false, label: "Estero" },
      ],
      cittadinanzaLookup: [
        { id: 1, label: "Italiana" },
        { id: 2, label: "Europea" },
        { id: 3, label: "Estera" },
      ],
      // prendo i valori qui sotto in sola lettura.
      stagione_prec: computed(() => store.getters.stagione_precRinnovo),
      stagione_rinn: computed(() => store.getters.stagione_rinnRinnovo),
      // cosi in caso di reset filtri, si svuotano tutti i campi
      cognome: computed(() => store.getters.cognomeRinnovo),
      codiceTessera: computed(() => store.getters.codiceTesseraRinnovo),
      data_emissione_da: computed(() => store.getters.data_emissione_daRinnovo),
      data_emissione_a: computed(() => store.getters.data_emissione_aRinnovo),
      // inizioTesseramento: computed(
      //   () => store.getters.inizioTesseramentoTesserato
      // ),
      // fineTesseramento: computed(() => store.getters.fineTesseramentoTesserato),
      // certificato: computed(() => store.getters.certificatoTesserato),
      id_qualifica: computed(() => store.getters.id_qualificaRinnovo),
      id_tipo_tessera: computed(() => store.getters.id_tipo_tesseraRinnovo),
      id_categoria: computed(() => store.getters.id_categoriaRinnovo),
      id_disciplina: computed(() => store.getters.id_disciplinaRinnovo),
      isDisabile: computed(() => store.getters.disabileRinnovo),
      isEstiva: computed(() => store.getters.estivaRinnovo),
      id_cittadinanza: computed(() => store.getters.cittadinanzaRinnovo),
      id_nazione: computed(() => store.getters.id_nazioneRinnovo),
      id_nazioni_generiche: computed(
        () => store.getters.id_nazioni_genericheRinnovo
      ),
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedrinnovi_list")
      ),
      setConsiglio,
      consiglio,
      setconsiglio,
    };
  },
});
</script>

<style></style>
